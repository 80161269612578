<template>
  <el-container class="h-v">
    <el-aside width="200px">
      <Aside />
    </el-aside>
    <el-container>
      <el-header>
        <hy-header></hy-header>
      </el-header>
      <el-main class="hy-main">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import RouterView from '../components/RouterView.vue'
import Aside from './components/Aside'
import HyHeader from './components/HyHeader'
export default {
  components: { RouterView, Aside, HyHeader }
}
</script>

<style lang="less" scoped>
.hy-main {
  background: #f6f7fb;
}
/* 定义滚动条宽度和背景颜色 */
.el-aside::-webkit-scrollbar {
  width: 6px; /* 对于垂直滚动条 */
  height: 6px; /* 对于水平滚动条 */
  background-color: #f9f9f9;
}

/* 定义滚动条滑块的样式 */
.el-aside::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 3px;
}

/* 定义滚动条滑块hover样式 */
.el-aside::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
::v-deep .el-main {
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    box-sizing: border-box;
     padding: 15px !important; 
     &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
</style>
