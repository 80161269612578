import request from "@/utils/request"

// 图片、文件上传
export function uploadAdmin(data) {
  return request({
    url: "/public/uploadFile",
    method: "post",
    data
  })
}

// 图片、文件上传
export function uploadOneFile(data) {
  return request({
    url: "/public/apiUpload",
    method: "post",
    data
  })
}


//codeType查询表
export function getCode(data) {
  return request({
    url: '/code/getCode',
    method: 'get',
    params: data
  })
}

// 查询城市
export function getCityAll(data) {
  return request({
    url: '/city/getCityAll',
    method: 'get',
    params: data
  })
}

// 单文件上传
export function apiUpload(data) {
  return request({
    url: '/public/apiUpload',
    method: 'POST',
    data
  })
}

// 单文件下载 传url
export function apiDownloadFile(query) {
  return request({
    url: '/public/downloadFile',
    responseType: "blob",
    method: 'get',
    params: query
  })
}

// 单文件下载 传url
export function getIndustryLabel(query) {
  return request({
    url: '/label/getIndustryLabel',
    method: 'get',
    params: query
  })
}

// 获取国外城市
export function getForeignArea() {
  return request({
    url: "/china/getForeignArea",
    method: "get"
  });
}
