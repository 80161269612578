// 组件----------->components/DictData/index.js
import Vue from 'vue'
//引入DataDict的模块或工具，包含数据字典相关的功能
import DataDict from '@/utils/dict'
//查询字典的方法（获取数据的接口）
import { getCode } from '@/api/common'

function install() {
//把DataDict当作一个插件安装到Vue实例中
  Vue.use(DataDict, {
//数据字典元信息
    metas: {
      //'*'表示这是一个通用配置，适用于所有数据字典
      '*': {
        //labelField 和 valueField 是用于指定数据字典项中标签和值的字段名
        labelField: 'codeName',
        valueField: 'codeId',
        //这个函数用来请求数据字典的数据，调用 getCode() 函数，并使用传入的 dictMeta.type 来获取相应类型的数据字典
        request(dictMeta) {
          return getCode({ 'codeType': dictMeta.type }).then(res => res.data.data)
        }
      }
    }
  })
}

export default {
  install
}
